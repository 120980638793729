import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../_services/authentication.service';
import { AdminauthenticationService } from '@utilityService/adminauthentication.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  urlsToSkiploading: Array<string>;
  locationPath: string = '';
  constructor(private authenticationService: AuthenticationService,private location: Location,private adminauth: AdminauthenticationService,private router: Router) {
    this.urlsToSkiploading = [
      'clientapp/verifyclient'
    ];
    router.events.subscribe((val: any) => {
      if(location.path() != ''){
        this.locationPath = location.path();
        // console.log(location.path())
      } else {
        // this.route = 'Home'
      }
      // console.log(val)
      // this.locationPath = val.route.path
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this.isValidRequestForInterceptor(request.url)) {
      // const currentUser = this.authenticationService.UserValue;
      let currentUser: any;
      if(this.locationPath.toLowerCase().includes('/admin/')){
        currentUser = this.adminauth.UserValue;
      }else {
        currentUser = this.authenticationService.UserValue;
      }
      const isLoggedIn = currentUser && currentUser.Token;
      if (isLoggedIn) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.Token}`
          }
        });
      }
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !request.url.endsWith('/refresh-token')) {
          // const currentUser = this.authenticationService.UserValue;
          let currentUser: any;
          if(this.locationPath.toLowerCase().includes('/admin/')){
            currentUser = this.adminauth.UserValue;
          }else {
            currentUser = this.authenticationService.UserValue;
          }
          console.log(currentUser);
          return this.authenticationService.refreshToken(currentUser.RefreshToken).pipe(
            switchMap((newAccessToken: string) => {
              request = this.addAccessTokenToRequest(request, newAccessToken);
              return next.handle(request);
            }),
            catchError((refreshError: any) => {
              return throwError(refreshError);
            })
          );
        }
        return throwError(error);
      })
    );;
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = 'api/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(position + positionIndicator.length);
      for (let address of this.urlsToSkiploading) {
        if (new RegExp(address.toLowerCase()).test(destination.toLowerCase())) {
          return false;
        }
      }
    }
    return true;
  }
  private addAccessTokenToRequest(request: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }
}


// import { Injectable } from '@angular/core';
// import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
// import { Observable, throwError } from 'rxjs';
// import { catchError, switchMap } from 'rxjs/operators';
// import { environment } from '../../../environments/environment';
// import { AuthenticationService } from '../_services/authentication.service';
// import { Router } from '@angular/router';
// import { Location } from '@angular/common';
// import { AdminauthenticationService } from '@utilityService/adminauthentication.service';

// @Injectable()
// export class JwtInterceptor implements HttpInterceptor {
//   urlsToSkiploading: Array<string>;
//   locationPath: string = '';
//   constructor(private authenticationService: AuthenticationService,private adminauth: AdminauthenticationService,location: Location, router: Router) {
//     this.urlsToSkiploading = [
//       'clientapp/verifyclient'
//     ];
//     router.events.subscribe((val: any) => {
//       if(location.path() != ''){
//         this.locationPath = location.path();
//         console.log(location.path())
//       } else {
//         // this.route = 'Home'
//       }
//       console.log(val)
//       // this.locationPath = val.route.path
//     });
//   }

//   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
//     if (this.isValidRequestForInterceptor(request.url)) {
//       const currentUser = this.authenticationService.UserValue;
//       const isLoggedIn = currentUser && currentUser.Token;
//       if (isLoggedIn) {
//         request = request.clone({
//           setHeaders: {
//             Authorization: `Bearer ${currentUser.Token}`
//           }
//         });
//       }
//     }
//     return next.handle(request).pipe(
//       catchError((error: HttpErrorResponse) => {
//           if (error.status === 401 && !request.url.endsWith('/refresh-token')) {
//             const currentUser = this.authenticationService.UserValue;
//             return this.authenticationService.refreshToken(currentUser.RefreshToken).pipe(
//               switchMap((newAccessToken: string) => {
//                 request = this.addAccessTokenToRequest(request, newAccessToken);
//                 return next.handle(request);
//               }),
//               catchError((refreshError: any) => {
//                 return throwError(refreshError);
//               })
//             );
//           }
//         return throwError(error);
//       })
//     );;
//   }

//   private isValidRequestForInterceptor(requestUrl: string): boolean {
//     let positionIndicator: string = 'api/';
//     let position = requestUrl.indexOf(positionIndicator);
//     if (position > 0) {
//       let destination: string = requestUrl.substr(position + positionIndicator.length);
//       for (let address of this.urlsToSkiploading) {
//         if (new RegExp(address.toLowerCase()).test(destination.toLowerCase())) {
//           return false;
//         }
//       }
//     }
//     return true;
//   }
//   private addAccessTokenToRequest(request: HttpRequest<any>, accessToken: string): HttpRequest<any> {
//     return request.clone({
//       setHeaders: {
//         Authorization: `Bearer ${accessToken}`
//       }
//     });
//   }
// }
