import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Base64 } from 'js-base64';

@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {

  encryptSecretKey: string = "SECRET_KEY";

  encryptData(data: any) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
    } catch (e) {
      console.log(e);
    }
    return '';
  }

  decryptData(data: any) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  encodeBase64(data: any) {
    var encrypted = Base64.encode(JSON.stringify(data)); //btoa(JSON.stringify(data));
    return encrypted.toString();
}
}
