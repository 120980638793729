import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { LoaderService } from '../_services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private currentRequests: number;
  constructor(
    private _loaderService: LoaderService) {
    this.currentRequests = 0;
  }

  skipLoading = ['Login/ChangeCustomer'.toLowerCase(),'LVApply/GetTotalDays'.toLowerCase()];

  checkLoadingRequired(url: string): boolean {
    let urlData: any = url.toLowerCase().split('api/');
    if (urlData.length == 2) {
      urlData = urlData[1]
    } else {
      return true;
    }
    if (this.skipLoading.includes(urlData)) { return false }
    return true;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.checkLoadingRequired(request.url) || request.body?.IsHideLoading) {
      return next.handle(request);
    }

    if (this._loaderService.autoloader) {
      this.incrementRequestCount();
    }

    return next.handle(request)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (this._loaderService.autoloader) {
              this.decrementRequestCount();
            }
          }
        }, (err: any) => {
          if (this._loaderService.autoloader) {
            this.currentRequests = 0;
            this._loaderService.isLoading.next(false);
          }
        })
      );
  }

  private decrementRequestCount() {
    if (--this.currentRequests === 0) {
      this._loaderService.isLoading.next(false);
    }
  }

  private incrementRequestCount() {
    if (this.currentRequests++ === 0) {
      this._loaderService.isLoading.next(true);
    }
  }



}
