import { DirectiveModule } from './utilities/_directives/directive/directive.module';
import { IconModule, IconSetService } from '@coreui/icons-angular';
import { FormModule } from '@coreui/angular';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Views/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './Views/loader/loader.component';
import { LoaderService } from '@utilityService/loader.service';
import { LoaderInterceptor } from '@utilityhelper/loader.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtInterceptor } from '@utilityhelper/jwt.interceptor';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorInterceptor } from '@utilityhelper/error.interceptor';
import { EncdeInterceptor } from '@utilityhelper/encdec-interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalErrorHandler } from '@utilityhelper/global-error-handler';
import { AdminLoginComponent } from './Views/admin/admin-login/admin-login.component';
import { SanitizeInterceptor } from './utilities/_helpers/sanitize-interceptor.service';
import {NgxMaskModule} from 'ngx-mask';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoaderComponent,
    AdminLoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormModule, ReactiveFormsModule,
    HttpClientModule, IconModule, FormsModule, ToastrModule.forRoot(), BrowserAnimationsModule,
    DirectiveModule, NgSelectModule,
    NgxMaskModule.forRoot(),
    NgbModule  ],
  providers: [LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: SanitizeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EncdeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    IconSetService],
  bootstrap: [AppComponent]
})
export class AppModule { }
