import { ConfirmationService } from '@utilityService/confirmation.service';
import { HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse, HttpInterceptor } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { LoaderService } from '../_services/loader.service';
import { AuthenticationService } from '../../utilities/_services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  urlsToSkiploading: Array<string>;
  pageName: any = null;
  constructor(public loaderService: LoaderService, public confirmService: ConfirmationService,
    private authenticationService: AuthenticationService) {
    this.urlsToSkiploading = [
      'AuthLogin/refreshtoken',
      'clientapp/verifyclient'
    ];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isValidRequestForInterceptor(request.url)) {
      // this.loaderService.show();
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {

        if (this.isValidRequestForInterceptor(request.url)) {
          console.log(error);
          // this.loaderService.hide();
          let errorMessage = '';
          let errorCoce = error.status;
          if (error.status == 0) {
            if (!navigator.onLine) {
              this.confirmService.errorModal('Error', 'Please check your internet connection.');
            } else {
              errorMessage = 'Error: ' + error.statusText + ' \t Message: ' + (error.message.includes("/api/") ? "Something went Wrong!" : error.message);
              this.confirmService.errorModal('Error', errorMessage);
            }

          }
          else if (error.status == 409) {//Invalid Input
            // errorMessage = 'Error:' + error.error.message;
            errorMessage = "Bad Request or (Incorrect input, Your input value should not contain < or > symbols)";
            this.confirmService.errorModal('Error', errorMessage);
          }
          else if (error.status == 401) {
            this.confirmService.unAuthorized().then(r=>{
              this.authenticationService.logout();
            });
            // this.confirmService.
            // alert(error.status);

          }
          else if (error.status > 401 && error.status <= 499) {
            errorMessage = 'Error: ' + error.statusText + ' \t Message: ' + (error.message.includes("/api/") ? "Something went wrong!" : error.message);
            this.confirmService.errorModal('Error', errorMessage);
            // alert(error.status);
            this.authenticationService.logout();
          }
          else if (error.status >= 500 && error.status <= 599) {
            // errorMessage = 'Error:' + error.error.message;
            errorMessage = "This page transaction process got error. Kindly raise ticket/inform support to check further."
            this.confirmService.errorModal('Error', errorMessage);
          }
          else {
            errorMessage = 'Error:' + error.error;
          }
          return throwError(errorMessage);
        }
        else {
          return throwError(error);
        }
      }),
      finalize(() => {
        // this.loaderService.hide();
      })
    ) as Observable<HttpEvent<any>>;
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = 'api/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(position + positionIndicator.length);
      for (let address of this.urlsToSkiploading) {
        if (new RegExp(address.toLowerCase()).test(destination.toLowerCase())) {
          return false;
        }
      }
    }
    return true;
  }
}
