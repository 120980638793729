import { AuthenticationService } from './authentication.service';
import { UserMenu } from '../_models/login.model';
import { LocalCacheService } from './local-cache.service';
import { Injectable, Inject } from '@angular/core';
import { Router, NavigationEnd, ResolveEnd } from '@angular/router';
import { EncryptDecryptService } from './encrypt-decrypt.service';

@Injectable({
  providedIn: 'root'
})

export class PageServices {
  public pageDetails!: UserMenu;

  constructor(private encdec: EncryptDecryptService, private local: LocalCacheService, private router: Router, private authService: AuthenticationService) {
    this.router.events
      .subscribe(arg => {
        if (arg instanceof ResolveEnd) {
          this.setPageDetails(arg);
        }

      });
  }



  setPageDetails(arg: any) {
    let users = this.authService.UserValue;
    if (users.UserData) {
      let routerurl = arg.url;
      let spliturl = routerurl.split("?");
      let url = spliturl[0];
      let titleMenu = users.UserData._usermenu.filter((x: any) => x.PageUrl == url);
      this.pageDetails = {} as UserMenu;
      if (titleMenu.length > 0) {
        this.pageDetails = titleMenu[0];
      }
    }

  }

}
