
import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';

@Directive({
  selector: '[appInvalidFocus]'
})
export class InvalidFocusDirective {

  constructor(private el: ElementRef) { }

  @Input() formGroup!: FormGroup;

  @HostListener('submit', ['$event'])
  public onSubmit(event: any): void {
    debugger;
    if ('INVALID' === this.formGroup.status) {
      event.preventDefault();

      const formGroupInvalid = this.el.nativeElement.querySelectorAll('.ng-invalid');

      if ((formGroupInvalid[0]).tagName == 'NG-SELECT') {

        (<HTMLInputElement>formGroupInvalid[0]).scrollIntoView({ behavior: 'smooth' });
        (<HTMLInputElement>formGroupInvalid[0]).click();
      } if ((formGroupInvalid[0]).tagName == 'APP-DATEPICKER') {
        // (formGroupInvalid[0]).scrollIntoView({ behavior: 'smooth' });
        (formGroupInvalid[0]).querySelector('input').focus();
        if (!!formGroupInvalid[0]) {
          setTimeout(() => {

            (formGroupInvalid[0]).querySelector('input').click();


          }, 800);

        }
      } else
        (<HTMLInputElement>formGroupInvalid[0]).focus();
    }
  }
}
