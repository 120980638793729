import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LocalCacheService } from '@utilityService/local-cache.service';
import { Observable } from 'rxjs';
import { AlertMessage } from './alert.message';
import { AdminauthenticationService } from '@utilityService/adminauthentication.service';
import { responsecls } from '@utilitymodel/responsecls';

@Injectable({
  providedIn: 'root'
})
export class AdminLoginGuard implements CanActivate {
  
  toastId = 0;
  constructor(private router: Router, private authenticationService: AdminauthenticationService, private alert: AlertMessage, private local: LocalCacheService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,) {

  //   let output: any = this.authenticationService.checkrememberMe();
  //   if (!!output) {
  //     this.login(output);
  //   } else {
  //     return true;
  //   }
  //   return false;
  // }
  
  // login(loginRequest: any) {


  //   this.authenticationService.login(loginRequest).subscribe((r: responsecls) => {
  //     if (r.IsSuccess) {

  //       this.router.navigate(['/Admin/home']);
  //     } else {
  //       this.toastId = this.alert.errorNotifier(r.StatusMessage, this.toastId);
  //       this.local.removelocalStorage(this.local.rememberMe);
  //     }
  //   });
  // }
}
