import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  isLoading = new Subject<boolean>();

  show() {
    this.isLoading.next(true);
  }
  hide() {
    this.isLoading.next(false);
  }

  Fakeloader(params?: boolean) {
    if(params){
      this.show();
      setTimeout(() => {
        this.hide();
      }, 1000);
    } else {
      console.log("loading not required")
    }
  }

   public autoloader = true;
  // public _toggleLoader: EventEmitter<any>;

  constructor() {
    this.isLoading.next(true);
    // this._toggleLoader = new EventEmitter<any>();
  }
  // toggleLoader(toggle: boolean) {
  //   this._toggleLoader.emit(toggle);
  // }
}
