import { LeaveApplycoldef } from './../../Views/leave/leave-apply-on-behalf/leave-apply-on-behalf.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MastersEntity } from '@utilitymodel/audit-log-info';
import { responsecls } from '@utilitymodel/responsecls';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { Country } from '@utilitymodel/login.model';

@Injectable({
  providedIn: 'root'
})
export class MasterService {



  constructor(private _http: HttpClient, private auth:AuthenticationService) {
    this.auth.setCountryEnvApiURL();
  }

  GetMastersList(obj: MastersEntity): Observable<responsecls> {
    return this._http.post<any>(environment.adminApiUrl + '/Common/GetMastersList', obj);
  }

  GetCommonStaffList(obj: any): Observable<responsecls> {
    return this._http.post<any>(environment.adminApiUrl + '/Common/GetCommonStaffList', obj);
  }
  GetCommonAuditlogList(obj: any): Observable<responsecls> {
    return this._http.post<any>(environment.adminApiUrl + '/Common/GetAuditLog', obj);
  }

  GetBlobFileURL(obj: any): Observable<responsecls> {
    return this._http.post<any>(environment.leaveApiUrl + '/LvApply/GetBlobFileURL', obj);
  }

  // GetViewPlan(obj: any): Observable<responsecls> {
  //   return this._http.post<any>(`${environment.crmApiUrl}/Checkout/GetCustomerPaymentSession`, obj);
  // }
  GetViewPlan(obj: any): Observable<responsecls> {
    return this._http.post<any>(`${environment.crmPaymentApiUrl}/Customer/GetCustomerPaymentSession`, obj);
  }
}
