import { AuditLogInfo } from '@utilitymodel/audit-log-info';
import { responsecls } from '@utilitymodel/responsecls';
import { LocalCacheService } from './local-cache.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { Country, Login, LoginResponse, UserCompany } from '@utilitymodel/login.model';
import * as moment from 'moment';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public currentUserSubject!: BehaviorSubject<Login>;
  public currentUser!: Observable<any>;

  us: any;
  private refreshTokenTimeout: any;
  idleState = 'Not started.';
  timedOut = false;

  constructor(private http: HttpClient, private router: Router, private local: LocalCacheService,
    private encdec: EncryptDecryptService) {

    if (localStorage.getItem(this.local.localUserKey) != null) {
      this.us = this.encdec.decryptData(localStorage.getItem(this.local.localUserKey));
      // let selectCompany = this.encdec.decryptData(localStorage.getItem(this.local.localCurrentUserKey));
      // this.currentCompany = new BehaviorSubject(selectCompany);
    }
    else {
      this.us = null;
    }
    this.currentUserSubject = new BehaviorSubject<Login>(this.us);
    this.currentUser = this.currentUserSubject.asObservable();


  }

  login(signInData: any) {
    return this.http.post<any>(environment.authguardApiUrl + '/Login/AuthenticateUser', signInData)
      .pipe(map((user: responsecls) => {
        if (user.IsSuccess) {
          localStorage.setItem(this.local.localUserKey, this.encdec.encryptData(user.StatusObject));
          this.local.getLocalStorageSize();
          let filterCompany = user.StatusObject.UserData._usercompany.find((r: any) => r.Company_Id === user.StatusObject.UserData.CompanyID)

          if (filterCompany === undefined && user.StatusObject.UserData._usercompany.length == 0) {
            localStorage.setItem(this.local.localUserKey, this.encdec.encryptData(user.StatusObject));
            this.local.getLocalStorageSize();
            this.currentUserSubject.next(user.StatusObject);
            return user;
          } else if (filterCompany === undefined) {
            filterCompany = user.StatusObject.UserData._usercompany[0];
          }
          console.log(filterCompany);


          if (user.StatusObject?.UserData.Country == Country.Singapore) {
            environment.leaveApiUrl = environment.sgLeaveApiUrl;
          } else if (user.StatusObject?.UserData.Country == Country.NewzLand) {
            environment.leaveApiUrl = environment.NZleaveApiUrl;
          }else if (user.StatusObject?.UserData.Country == Country.Malaysia) {
            environment.leaveApiUrl = environment.MyLeaveApiUrl;
          } 
          else {
            environment.leaveApiUrl = environment.glleaveApiUrl;
          }

          this.selectCompany(filterCompany, user.StatusObject);
        }
        return user;
      }));
  }


  ForgotPassword(signInData: any): Observable<responsecls> {
    return this.http.post<any>(environment.authguardApiUrl + '/Login/ForgotPassword', signInData);
  }

  rememberMe(obj: any) {
    localStorage.setItem(this.local.rememberMe, this.encdec.encryptData(obj));
  }

  checkrememberMe() {
    let data = null;
    if (localStorage.hasOwnProperty(this.local.rememberMe)) {
      data = localStorage.getItem(this.local.rememberMe);
      if (!!data) {
        data = (this.encdec.decryptData(data));
      }
    }
    return data;
  }

  public get UserValue() {
    return this.currentUserSubject.value;
  };
  refreshToken(refreshToken: any) {
    let obj = {
      RefreshToken: refreshToken
    }
    return this.http.post<any>(environment.authguardApiUrl + '/Token/refresh-token', obj)
      .pipe(map((user: responsecls) => {
        if (user.IsSuccess) {
          let customerDetails = this.UserValue;
          customerDetails.Token = user.StatusObject.Token;
          localStorage.setItem(this.local.localUserKey, this.encdec.encryptData(customerDetails));
          return user.StatusObject.Token;
        }
        else {
          return '';
        }
      }));
  }
  public get AuditLogInfo(): AuditLogInfo {
    let auditInfo: AuditLogInfo = {
      userid: this.currentUserSubject.value.UserData.userID,
      companyid: this.UserValue.UserData.CompanyID,
      appid: 0,
      flag: '',
      customerID: this.UserValue.UserData.CustomerID,
      empCode: this.UserValue.UserData.EmpCode,
      countrycode: this.UserValue.UserData.Country,
    }
    return auditInfo;
  }

  deepFreeze(obj: any): any {
    // Retrieve all property names of the object
    const propNames = Object.getOwnPropertyNames(obj);
    // Freeze the object itself
    Object.freeze(obj);
    // Freeze all properties of the object
    propNames.forEach((prop: string) => {
      const value = obj[prop];
      // Recursively freeze nested objects
      if (typeof value === 'object' && value !== null) {
        this.deepFreeze(value);
      }
    });
    return obj;
  }
  selectCompany(obj: any, data: any) {
    data.UserData.CompanyID = obj.Company_Id;
    data.UserData.CompanyName = obj.CompanyName;
    data.UserData.CompanyCode = obj.CompanyCode;
    data.UserData.com = obj.CompanyName;    
    localStorage.setItem(this.local.localUserKey, this.encdec.encryptData(data));
    this.local.getLocalStorageSize();
    this.currentUserSubject.next(data);

    if (data.UserData.ShiftorSch != obj.ShiftorSch) {
      data.UserData.ShiftorSch = obj.ShiftorSch;
      this.GetUserMenuOnChangeSettings({ user_id: data.UserData.userID, ShiftorSch: obj.ShiftorSch, _auditLogInfo: this.AuditLogInfo }).subscribe(res => {
      })
    }

  }

  updateLicence(data: any) {
    this.UserValue.UserData.LicenseDate = data.LicenseEndDate;
    this.UserValue.UserData.HeadCount = data.HeadCount;
    localStorage.setItem(this.local.localUserKey, this.encdec.encryptData(this.UserValue));
    // this.currentUserSubject.next(this.UserValue);
  }

  updateSkipWizard() {
    this.UserValue.UserData.SkipWizard = true;
    localStorage.setItem(this.local.localUserKey, this.encdec.encryptData(this.UserValue));
    // this.currentUserSubject.next(this.UserValue);
  }

  updateTrial() {
    this.UserValue.UserData.TrialStatus = 'NO';
    localStorage.setItem(this.local.localUserKey, this.encdec.encryptData(this.UserValue));
  }

  logout() {
    this.router.navigate(['/login']);
    this.local.removeLocalCache();
    this.currentUserSubject.next({} as Login);
    // this.currentCompany.next(null);
  }

  async VerifyKey(key: any) {
    let params = new HttpParams();
    params = params.set('key', key);
    let response = await this.http.post(environment.authguardApiUrl + '/Login/verifykey', params);
    return response;
  }

  SaveVerifyChange(key: any, Password: any) {
    let params = new HttpParams();
    params = params.set('key', key);
    params = params.set('Password', this.encdec.encodeBase64(Password));
    let response = this.http.post(environment.authguardApiUrl + '/Login/saveverifychange', params);
    return response;
  }

  ProcessSubUser(data: any): Observable<any> {
    return this.http.post<any>(environment.authguardApiUrl + '/Login/ProcessSubUser', data);
  }

  SearchCustomer(data: any): Observable<responsecls> {
    return this.http.post<any>(environment.authguardApiUrl + '/Login/ChangeCustomer', data);
  }

  ChangeCustomer(data: any): Observable<responsecls> {
    console.log(data);
    return this.http.post<any>(environment.authguardApiUrl + '/Login/ChangeCustomer', data)
      .pipe(map((user: responsecls) => {
        if (user.IsSuccess) {
          localStorage.setItem(this.local.localUserKey, this.encdec.encryptData(user.StatusObject));
          this.local.getLocalStorageSize();
          let filterCompany = user.StatusObject.UserData._usercompany.find((r: any) => r.Company_Id === user.StatusObject.UserData.CompanyID)
          this.selectCompany(filterCompany, user.StatusObject);
        }
        return user;
      }));
  }

  GetUserMenuOnChangeSettings(data: any): Observable<responsecls> {
    return this.http.post<any>(environment.adminApiUrl + '/User/GetUserMenu', data)
      .pipe(map((user: responsecls) => {
        if (user.IsSuccess) {
          this.UserValue.UserData._usermenu = user.StatusObject;
          this.UserValue.UserData.ShiftorSch = data.ShiftorSch;
          localStorage.setItem(this.local.localUserKey, this.encdec.encryptData(this.UserValue));
          this.local.getLocalStorageSize();
          this.currentUserSubject.next(this.UserValue);
        }
        return user;
      }));
  }

  setCountryEnvApiURL() {
    if (this.UserValue.UserData.Country == Country.Singapore) {
      environment.leaveApiUrl = environment.sgLeaveApiUrl;
    } else if (this.UserValue.UserData.Country == Country.NewzLand) {
      environment.leaveApiUrl = environment.NZleaveApiUrl;
    }else if (this.UserValue.UserData.Country == Country.Malaysia) {
      environment.leaveApiUrl = environment.MyLeaveApiUrl;
    }  
    else {
      environment.leaveApiUrl = environment.glleaveApiUrl;
    }
  }
  // setCountryEnvApiURL() {
  //   if (this.UserValue.UserData.Country == Country.Singapore) {
  //     environment.payrollApiUrl = environment.payrollApiUrl;
  //   }else if (this.UserValue.UserData.Country == Country.NewzLand) {
  //     environment.payrollApiUrl = environment.NzpayrollApiUrl;
  //   } else {
  //     environment.payrollApiUrl = environment.payrollApiUrl;
  //   }
  // }

  sendCRMUpdateLogin() {
    // let obj =
    // {
    //   "HrmsLastLogin": 'YES',
    //   "CustomerCode": this.UserValue.UserData.CustomerCode
    // }

    // this.http.post<any>(environment.crmApiUrl + '/Customer/UpdateLastLogin', obj).subscribe(r => {

    // })
  }


}
