import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AdminauthenticationService } from '@utilityService/adminauthentication.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: AdminauthenticationService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let url = '';
      url = state.url.split('?')[0];
      if (state.url.split('/')[1].toLowerCase() == 'admin') {
        // this.router.navigate(['/Admin/Login']);
        window.location.href = '/Admin/Login';
      } else {
        // this.router.navigate(['/Login']);
        window.location.href = '/Login';
      }
    return true;
  }
  
}
