import { Directive, HostListener, ElementRef, OnInit, Renderer2, NgModule, ViewChild, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AlertMessage } from '../_helpers/alert.message';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[alphabets]'
})

export class Alphabets {
  regEx = /^[A-Za-z\s]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event: KeyboardEvent) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[alphabetnumeric]'
})
export class AlphabetNumeric {
  regEx = /^[A-Za-z0-9\s]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event: KeyboardEvent) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[accountcode]'
})
export class AccountCode {
  regEx = /^[A-Za-z0-9\/._-]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event: KeyboardEvent) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[textwithlimitedspecialchar]'
})
export class TextWithLimitedSpecialChar {
  regEx = /^[a-zA-Z0-9#@ /]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event: KeyboardEvent) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[alphabetnumericsymbol]'
})
export class AlphabetNumericSymbol {
  regEx = /^[A-Za-z0-9&(),.-\s]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event: KeyboardEvent) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[alphabetsymbol]'
})
export class AlphabetSymbol {
  regEx = /^[A-Za-z&(),.-\s]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event: KeyboardEvent) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[phonenumber]'
})
export class PhoneNumber {
  regEx = /^[0-9]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event: KeyboardEvent) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[numbers]'
})
export class Numbers {
  regEx = /^[0-9]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }

  @HostListener('paste', ['$event']) onPaste(e: ClipboardEvent) {
    if (!!e.clipboardData) {
      let event = e.clipboardData.getData('text');
      //allow paste only number values
      if (!Number(event)) {
        e.preventDefault();
      }
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(e: any) {
    if (!!e) {
        e.preventDefault();
      }
    }

  validateRegEx(event: KeyboardEvent) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[appSixDigitTwoDecimal]'
})
export class SixDigitTwoDecimalDirective {

  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const inputValue = this.el.nativeElement.value;
    const regex = /^(?=.*\d)\d{0,6}(\.\d{0,2})?$/; 
    if (!regex.test(inputValue)) {
      this.control.control?.setValue(inputValue.substring(0, inputValue.length - 1));
    }
  }
}
@Directive({
  selector: '[decimal2_old]'
})
export class Decimal2 {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal', '.'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}




@Directive({
  selector: '[decimals]'
})
export class Decimals {
  private regex: RegExp = new RegExp(/^-?\d*\.?\d{0,2}$/);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal', '.'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}


@Directive({
  selector: '[negdecimal2]'
})
export class NegDecimal2 {
  private regex: RegExp = new RegExp(/^-?\d*(\.\d+)?$/g);
  //private regex: RegExp = new RegExp(/^[-]([.]\d+|\d+([.]\d+)?)$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal', '.'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    console.log("posti" + position);
    console.log("postion" + current.slice(0, position));
    console.log("posti" + current.slice(position));
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    console.log("posti" + position);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }


  @HostListener('drop', ['$event'])
  onDrop(e: any) {
    debugger
    if (!!e) {
        e.preventDefault();
      }
    }
}

@Directive({
  selector: '[negdecimal10]'
})
export class NegDecimal10 {
  private regex: RegExp = new RegExp(/^-?\d*(\.\d+)?$/g);
  //private regex: RegExp = new RegExp(/^[-]([.]\d+|\d+([.]\d+)?)$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal', '.'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    console.log("posti" + position);
    console.log("postion" + current.slice(0, position));
    console.log("posti" + current.slice(position));
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    console.log("posti" + position);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}


@Directive({
  selector: '[decimal3]'
})
export class Decimal3 {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,3}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal', '.'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[decimal5]'
})
export class Decimal5 {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,5}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal', '.'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}


@Directive({
  selector: '[decimal10]'
})
export class Decimal10 {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,10}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal', '.'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[decimal6]'
})
export class Decimal6 {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,7}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}


@Directive({
  selector: '[date]'
})
export class _Date {
  // constructor(private _el: ElementRef) { }
  // @HostListener('input', ['$event']) onInputChange(event: any) {
  //     const initalValue = this._el.nativeElement.value;
  //     this._el.nativeElement.value = initalValue.replace(/[^0-9-/.]*/g, '');
  //     if (initalValue !== this._el.nativeElement.value) {
  //         event.stopPropagation();
  //     }
  // }
  regEx = /^[0-9/]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      //(event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event: KeyboardEvent) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[time]'
})
export class _Time {
  // constructor(private _el: ElementRef) { }
  // @HostListener('input', ['$event']) onInputChange(event: any) {
  //     const initalValue = this._el.nativeElement.value;
  //     this._el.nativeElement.value = initalValue.replace(/[^0-9-/.]*/g, '');
  //     if (initalValue !== this._el.nativeElement.value) {
  //         event.stopPropagation();
  //     }
  // }
  regEx = /^(?:(?:[01]?[0-9]|2[0-3])(?::(?:[0-5][0-9]?)?)?)?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      //(event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event: KeyboardEvent) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}


@Directive({
  selector: '[alphabethyphen]'
})
export class AlphabetHyphen {
  regEx = /^[A-Za-z-\s]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event: KeyboardEvent) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[phonecodenumber]'
})
export class PhoneCodeNumber {
  regEx = /^[0-9+-]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      //(event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event: KeyboardEvent) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[appNoRightClick]'
})
export class appNoRightClick {
  @HostListener('contextmenu', ['$event'])
  onRightClick(event: { preventDefault: () => void; }) {
    event.preventDefault();
  }
  constructor() { }
}


@Directive({
  selector: '[decimal2]'
})
export class TwoDigitDecimaNumberDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  // private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal'];

  constructor(private el: ElementRef, private control: NgControl,) {
  }

  ngOnInit() {
    const initialOnChange = (this.control.valueAccessor as any).onChange;
    (this.control.valueAccessor as any).onChange = (value: string) => initialOnChange(this.verifyEmpty(value));
  }

  @HostListener('blur', ['$event'])
  OnBlur($event: KeyboardEvent) {
    let str: string = this.el.nativeElement.value;
    this.control.valueAccessor?.writeValue(this.verifyEmpty(str));
  }

  verifyEmpty(str: string) {
    if (!str) {
      return '0.00';
    } if (str == '.') {
      return '0.00';
    } if (str[0] == '.') {
      return '0' + str;
    } else {
      return str;
    }
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    console.log("postiDDDDDd" + position);
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(e: any) {
    if (!!e) {
        e.preventDefault();
      }
    }
}

@Directive({
  selector: '[latlang]'
})
export class LatLangDecimaNumberDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,10}$/g);
  // private regex: RegExp = new RegExp(/((\+|-)?(\d+)((\.\d{1,16})?))$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  // private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal'];

  constructor(private el: ElementRef, private control: NgControl,) {
  }

  ngOnInit() {
    const initialOnChange = (this.control.valueAccessor as any).onChange;
    (this.control.valueAccessor as any).onChange = (value: string) => initialOnChange(this.verifyEmpty(value));
  }

  @HostListener('blur', ['$event'])
  OnBlur($event: KeyboardEvent) {
    let str: string = this.el.nativeElement.value;
    this.control.valueAccessor?.writeValue(this.verifyEmpty(str));
  }

  verifyEmpty(str: string) {
    if (!str) {
      return 0;
    } else {
      return str;
    }
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    console.log("postiDDDDDd" + position);
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[xssDirective]'
})
export class XssDirective {
  statusMsgID = 0;
  constructor(private _el: ElementRef, private alertMessage: AlertMessage, private control: NgControl, private _sanitize: DomSanitizer) { }

  ngOnInit() {
    const initialOnChange = (this.control.valueAccessor as any).onChange;
    (this.control.valueAccessor as any).onChange = (value: string) => initialOnChange(this.verify(value));
  }

  @HostListener('blur', ['$event'])
  OnBlur($event: KeyboardEvent) {
    let str: string = this._el.nativeElement.value;
    this.control.valueAccessor?.writeValue(this.verify(str).trim());
  }

  @HostListener('ngModelChange', ['$event'])
  ngModelChange(value: any) {
    let str = this.verify(value);
    if (str == "")
      this.control.valueAccessor?.writeValue(str);
  }

  // isHTML(str) {
  //   var doc = new DOMParser().parseFromString(str, "text/html");
  //   return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
  // }

  // verify(str: string) {
  //   if (str != undefined && str != null) {
  //     str = str.normalize('NFKC');
  //     if (this.isHTML(str)) {
  //       // this._el.nativeElement.value = "";
  //       str = "";
  //       this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
  //     }
  //     if (str && String(str).includes("cmd")) {
  //       // this._el.nativeElement.value = "";
  //       str = "";
  //       this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
  //     }
  //   }
  //   return str;
  // }

  isHTML(str: string) {
    var a = document.createElement('div');
    a.innerHTML = str;
    for (var c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType == 1)
        return true;
    }
    return false;
  }

  isURL(str: string) {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    var urls = str.match(urlRegex);
    if ((urls != undefined && urls.length > 0) || str.includes('localhost')) {
      return true;
    } else return false;
  }

  verify(str: string) {
    if (str != undefined && str != null && str != '') {
      if (typeof str == 'number' && !isNaN(str)) {
        return str;
      }
      str = str.normalize('NFKC');
      if (this.isURL(str)) {
        str = "";
        this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
      }
      else if (this.isHTML(str) || this._sanitize.sanitize(1, str) == '') {
        str = "";
        this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
      }
      else if (String(str).includes("cmd") || String(str).includes("<script")) {
        str = "";
        this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
      }
    }
    return str;
  }
}


@Directive({
  selector: '[xssURLDirective]'
})
export class xssURLDirective {
  statusMsgID = 0;
  constructor(private _el: ElementRef, private alertMessage: AlertMessage, private control: NgControl, private _sanitize: DomSanitizer) { }

  ngOnInit() {
    const initialOnChange = (this.control.valueAccessor as any).onChange;
    (this.control.valueAccessor as any).onChange = (value: string) => initialOnChange(this.verify(value));
  }

  @HostListener('blur', ['$event'])
  OnBlur($event: KeyboardEvent) {
    let str: string = this._el.nativeElement.value;
    this.control.valueAccessor?.writeValue(this.verify(str).trim());
  }

  @HostListener('ngModelChange', ['$event'])
  ngModelChange(value: any) {
    let str = this.verify(value);
    if (str == "")
      this.control.valueAccessor?.writeValue(str);
  }

  isHTML(str: string) {
    var a = document.createElement('div');
    a.innerHTML = str;
    for (var c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType == 1)
        return true;
    }
    return false;
  }

  verify(str: string) {
    if (str != undefined && str != null && str != '') {
      if (typeof str == 'number' && !isNaN(str)) {
        return str;
      }
      str = str.normalize('NFKC');
      if (this.isHTML(str) || this._sanitize.sanitize(1, str) == '') {
        str = "";
        this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
      }
      if (String(str).includes("cmd") || String(str).includes("<script")) {
        str = "";
        this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
      }
    }
    return str;
  }
}


@Directive({
  selector: '[maxNum]'
})
export class MaxNum {

  @Input() maxNum: number = 0;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal', '.'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    console.log("posti" + position);
    console.log("postion" + current.slice(0, position));
    console.log("posti" + current.slice(position));
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    console.log("posti" + position);
    if (!isNaN(Number(next))) {
      if (Number(next) > this.maxNum)
        event.preventDefault();
    }

  }
}

@NgModule({
  declarations: [Decimals,Alphabets, AlphabetNumeric, AccountCode, TextWithLimitedSpecialChar, AlphabetNumericSymbol, AlphabetSymbol, PhoneNumber, Numbers, Decimal2, Decimal3, Decimal6, _Date, AlphabetHyphen, PhoneCodeNumber, appNoRightClick, NegDecimal2, TwoDigitDecimaNumberDirective, XssDirective, xssURLDirective, _Time, MaxNum, Decimal5, Decimal10, NegDecimal2, NegDecimal10,LatLangDecimaNumberDirective],
  exports: [Decimals,Alphabets, AlphabetNumeric, AccountCode, TextWithLimitedSpecialChar, AlphabetNumericSymbol, AlphabetSymbol, PhoneNumber, Numbers, Decimal2, Decimal3, Decimal6, _Date, AlphabetHyphen, PhoneCodeNumber, appNoRightClick, NegDecimal2, TwoDigitDecimaNumberDirective, XssDirective, xssURLDirective, _Time, MaxNum, Decimal5, Decimal10, NegDecimal2, NegDecimal10,LatLangDecimaNumberDirective]
})

export class AllowKeypressDirectiveModule { }
