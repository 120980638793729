import { Directive, Input, TemplateRef, ViewContainerRef, NgModule } from "@angular/core";
import { LocalCacheService } from "@utilityService/local-cache.service";
import { PageServices } from '../_services/page.service';

@Directive({
  selector: "[userCanEdit]"
})
export class userCanEditDirective {
  allowedRoles: any;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private page: PageServices) {

  }

  @Input()
  set userCanEdit(allowedRoles: boolean) {
    this.allowedRoles = allowedRoles;
    if (!this.allowedRoles || !this.page.pageDetails.CanEdit) {
      this.viewContainer.clear();
      return;
    }
    const allowed: boolean = this.page.pageDetails.CanEdit === this.allowedRoles;
    if (allowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else {
      this.viewContainer.clear();
    }
  }
}



@Directive({
  selector: "[userCanDelete]"
})
export class userCanDeleteDirective {
  allowedRoles: any;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private page: PageServices) {

  }

  @Input()
  set userCanDelete(allowedRoles: string) {
    this.allowedRoles = allowedRoles;
    if (!this.allowedRoles || this.allowedRoles.length === 0 ||
      !this.page.pageDetails.CanEdit) {
      this.viewContainer.clear();
      return;
    }

    const allowed: boolean = this.page.pageDetails.CanEdit === this.allowedRoles[0];
    if (allowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else {
      this.viewContainer.clear();
    }
  }
}


@Directive({
  selector: "[userCanApprove]"
})
export class usercanApproveDirective {
  allowedRoles: any;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private page: PageServices) {

  }

  @Input()
  set userCanApprove(allowedRoles: string) {
    this.allowedRoles = allowedRoles;
    if (!this.allowedRoles || this.allowedRoles.length === 0 ||
      !this.page.pageDetails.CanEdit) {
      this.viewContainer.clear();
      return;
    }

    const allowed: boolean = this.page.pageDetails.CanEdit === this.allowedRoles[0];
    if (allowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else {
      this.viewContainer.clear();
    }
  }
}

@Directive({
  selector: "[userCanView]"
})
export class usercanViewDirective {
  allowedRoles: any;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private page: PageServices) {

  }

  @Input()
  set userCanView(allowedRoles: string) {
    this.allowedRoles = allowedRoles;
    if (!this.allowedRoles || this.allowedRoles.length === 0 ||
      !this.page.pageDetails.CanEdit) {
      this.viewContainer.clear();
      return;
    }

    const allowed: boolean = this.page.pageDetails.CanEdit === this.allowedRoles[0];
    if (allowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else {
      this.viewContainer.clear();
    }
  }
}


@NgModule({
  declarations: [userCanEditDirective, userCanDeleteDirective, usercanApproveDirective, usercanViewDirective],
  exports: [userCanEditDirective, userCanDeleteDirective, usercanApproveDirective, usercanViewDirective]
})

export class UserAccessDirectiveModule { }
