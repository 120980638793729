import { LoginComponent } from './Views/login/login.component';
import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '@utilityhelper/login.guard';
import { AdminLoginComponent } from './Views/admin/admin-login/admin-login.component';
import { AdminLoginGuard } from '@utilityhelper/admin-login.guard';
import { RedirectGuard } from '@utilityhelper/redirect.guard';

const routes: Routes = [
  {
    path: 'Login',
    component: LoginComponent,
    canActivate:[LoginGuard]
  },
  {
    path: 'Admin/Login',
    component: AdminLoginComponent,
    canActivate:[AdminLoginGuard]
  },
  {
    path: 'Digi',
    loadChildren: () =>
      import('./Views/layout/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: 'Admin',
    loadChildren: () =>
      import('./Views/layout/layout.module').then((m) => m.LayoutModule),
  },
  { path: '**',component: LoginComponent, canActivate:[RedirectGuard] },
  // { path: '**', redirectTo: '/Login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
