import { Directive, HostListener, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements OnInit {
  @Input() field!: string
  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 100);
  }
}
