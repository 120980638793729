<div class="modal-content" *ngIf="type == 'success'">
  <div class="modal-header bg-white border-bottom-0">
    <span class="modal-header-icon">
      <i class="icon-success"></i>
    </span>
  </div>
  <div class="modal-body">
    <h5 class="modal-title fw-bold text-center tx-18 mb-2">{{title}}</h5>
    <div class="row justify-content-center">
      <div class="col-9">
        <p class="tx-14 text-center mb-0">{{message}}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-primary rounded-pill ripple px-5 my-1"
      (click)="accept()">{{btnOkText}}</button>
  </div>
</div>

<div class="modal-content" *ngIf="type == 'add'">
  <div class="modal-header">
    <span class="modal-header-icon">
      <i class="icon-add"></i>
    </span>
    <h5 class="modal-title text-center tx-18">Add New Department</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-control-label">Department Code<em>*</em></label>
            <input type="text" class="form-control rounded-pill" placeholder="Department Code">
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-control-label">Department Name<em>*</em></label>
            <input type="text" class="form-control rounded-pill" placeholder="Department Name">
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-danger rounded-pill ripple px-5 my-1">{{btnCancelText}}</button>
    <button type="button" class="btn btn-primary rounded-pill ripple px-5 my-1">{{btnOkText}}</button>
  </div>
</div>

<div class="modal-content" *ngIf="type == 'warning'">
  <div class="modal-header bg-white border-bottom-0">
    <span class="modal-header-icon">
      <i class="icon-warning"></i>
    </span>
  </div>
  <div class="modal-body">
    <h5 class="modal-title fw-bold text-center tx-18 mb-2">{{title}}</h5>
    <div class="row justify-content-center">
      <div class="col-9">
        <p class="tx-14 text-center mb-0">{{message}}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-primary rounded-pill ripple px-5 my-1"
      (click)="accept()">{{btnOkText}}</button>
  </div>
</div>

<div class="modal-content" *ngIf="type == 'error'">
  <div class="modal-header bg-white border-bottom-0">
    <span class="modal-header-icon">
      <i class="icon-error"></i>
    </span>
  </div>
  <div class="modal-body">
    <h5 class="modal-title fw-bold text-center tx-18 mb-2">{{title}}</h5>
    <div class="row justify-content-center">
      <div class="col-9">
        <p class="tx-14 text-center mb-0">{{message}}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <!-- <button type="button" class="btn btn-danger rounded-pill ripple px-5 my-1">{{btnCancelText}}</button> -->
    <button type="button" class="btn btn-primary rounded-pill ripple px-5 my-1"
      (click)="accept()">{{btnOkText}}</button>
  </div>
</div>

<div class="modal-content" *ngIf="type == 'delete'">
  <div class="modal-header bg-white border-bottom-0">
    <span class="modal-header-icon">
      <i class="icon-delete"></i>
    </span>
  </div>
  <div class="modal-body">
    <h5 class="modal-title fw-bold text-center tx-18 mb-2">{{title}}</h5>
    <div class="row justify-content-center">
      <div class="col-9">
        <p class="tx-14 text-center mb-0">{{message}}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-danger rounded-pill ripple px-5 my-1"
      (click)="decline()">{{btnCancelText}}</button>
    <button type="button" class="btn btn-primary rounded-pill ripple px-5 my-1"
      (click)="accept()">{{btnOkText}}</button>
  </div>
</div>

<div class="modal-content" *ngIf="type == 'confirm'">
  <div class="modal-header bg-white border-bottom-0">
    <span class="modal-header-icon" *ngIf="icon != ''">
      <i [class]="icon"></i>
    </span>
  </div>
  <div class="modal-body">
    <h5 class="modal-title fw-bold text-center tx-18 mb-2">{{title}}</h5>
    <div class="row justify-content-center">
      <div class="col-9">
        <p class="tx-14 text-center mb-0" [innerHTML]="message"></p>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-danger rounded-pill ripple px-5 my-1"
      (click)="decline()">{{btnCancelText}}</button>
    <button type="button" class="btn btn-primary rounded-pill ripple px-5 my-1"
      (click)="accept()">{{btnOkText}}</button>
  </div>
</div>



<div class="modal-content" *ngIf="type == 'unAuthorized'">
  <div class="modal-header bg-white border-bottom-0">
    <span class="modal-header-icon">
      <i class="icon-warning"></i>
    </span>
  </div>
  <div class="modal-body">
    <h5 class="modal-title fw-bold text-center tx-18 mb-2">Un-Authorized</h5>
    <div class="row justify-content-center">
      <div class="col-9">
        <p class="tx-14 text-center mb-0">Token expired. Re-Login to continue. </p>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-primary rounded-pill ripple px-5 my-1"
      (click)="accept()">Ok ({{ 10-num }})</button>
  </div>
</div>

<div class="modal-content" *ngIf="type == 'plan'">
  <!-- <div class="modal-header bg-white border-bottom-0">
    <span class="modal-header-icon">
      <i class="icon-warning"></i>
    </span>
  </div> -->
  <div class="modal-body">
    <h5 class="modal-title fw-bold text-center tx-18 mb-2">Oops! License has expired.</h5>
    <div class="row justify-content-center">
      <div class="col-9">
        <!-- <p class="tx-14 text-center mb-0">Your free trail has ended. but its easy to get connected again! Pick your plan and subscribe to DigiSme</p> -->
         <p class="tx-14 text-center mb-0">Your License has ended. Click the button below to view our plans to enjoy using DigiSME again! View Plans</p>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-primary rounded-pill ripple px-5 my-1"
      (click)="OnClickViewPlan()">View  Plan</button>
  </div>
</div>




<div class="modal-content" *ngIf="type == 'YT'">

  <div>

  </div>
    <youtube-player [videoId]="videoId" [playerVars]="playerVars" (ready)="savePlayer($event)" ></youtube-player>


  </div>
