<div class="app-content">
  <main class="flex-grow-1">
    <div class="auth-fluid">
      <div class="auth-fluid-content-box">
        <div class="wave"></div>
        <!-- <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
          <path d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z" style="stroke: none; fill:#1f3966;"></path>
        </svg> -->
        <div class="carousel-wrapper mx-auto position-relative">
          <div class="text-center mb-4">
            <div class="display-6 tx-warning fw-bold">DigiSME HRMS</div>
            <div class="lead fw-normal tx-white">SMEs Best Friend to Ease HR Matters</div>
          </div>
          <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="false">
            <!-- <div class="carousel-indicators mb-0">
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                aria-label="Slide 3"></button>
            </div> -->
            <div class="carousel-inner">
              <div class="carousel-item active">
                <figure class="mb-0">
                  <img src="assets/images/slider-1.png" class="d-block w-100" alt="...">
                </figure>
                <div class="carousel-caption text-center mb-4">
                  <h5 class="tx-18 tx-primary mb-3">Time Attendance, Leave Management, Payroll Customizable Reports</h5>
                  <button (click)="navigatetoSME()" class="btn btn-outline-primary px-4 rounded-pill ripple">Find Out More</button>
                </div>
              </div>
              <div class="carousel-item">
                <figure class="mb-0">
                  <img src="assets/images/slider-1.png" class="d-block w-100" alt="...">
                </figure>
                <div class="carousel-caption text-center mb-4">
                  <h5 class="tx-18 tx-primary mb-3">Time Attendance, Leave Management, Payroll Customizable Reports</h5>
                  <a class="btn btn-outline-primary px-4 rounded-pill ripple">Find Out More</a>
                </div>
              </div>
              <div class="carousel-item">
                <figure class="mb-0">
                  <img src="assets/images/slider-1.png" class="d-block w-100" alt="...">
                </figure>
                <div class="carousel-caption text-center mb-4">
                  <h5 class="tx-18 tx-primary mb-3">Time Attendance, Leave Management, Payroll Customizable Reports</h5>
                  <a class="btn btn-outline-primary px-4 rounded-pill ripple">Find Out More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="auth-fluid-form-box">
        <div class="form-box-body bg-white">
          <form [formGroup]="loginForm" appInvalidFocus>
            <div *ngIf="!showforgot">
              <h1 class="mb-2"><b class="fw-bold tx-primary"><span class="tx-warning">Digi</span>SME</b></h1>
              <h4 class="tx-primary mb-4">Login to DigiSME HRMS</h4>
              <div class="mb-4">
                <label class="form-control-label">Email or Mobile Number</label>
                <input type="text" appAutofocus tabindex="1" class="form-control form-control-lg rounded-pill" xssDirective
                  formControlName="UserName" placeholder="Enter your Email/Mobile Number">
              </div>
              <div class="mb-4">
                <div class="row">
                  <div class="col">
                    <label class="form-control-label">Password</label>
                  </div>
                  <div class="col col-auto">
                    <a (click)="loginType()" type="button" class="btn btn-sm btn-link text-decoration-none p-0">Forgot
                      Password?</a>
                  </div>
                </div>
                <!-- <input type="password" tabindex="2" class="form-control form-control-lg rounded-pill"
                  formControlName="Password" placeholder="Enter your Password"> -->
                <div class="input-group mb-1">
                  <input [type]="pwdType" tabindex="2" autocomplete="off" formControlName="Password" class="form-control form-control-lg"
                    placeholder="Enter your Password"  />
                  <button type="button" (mousedown)="pwdType='text'" (mouseup)="pwdType='password'" style="background-color: rgba(224, 224, 224, 0.4) !important;border:0;" class="btn pwd-show"
                    id="PSpreview1"><i class="fa-solid fa-eye tx-gray"></i></button>
                  <!-- <password-strength-meter [password]="f.NewPassword.value"></password-strength-meter> -->
                </div>
              </div>
              <div class="mb-4">
                <div class="d-flex fw-bold align-items-center">
                  <input formControlName="RememberMe" type="checkbox">Remember me
                </div>
              </div>
              <hr class="mb-4">
              <button type="submit" (click)="login()" tabindex="3"
                class="btn btn-lg btn-primary rounded-pill fw-bold ripple w-100">LOGIN</button>
            </div>
          </form>
          <form [formGroup]="ForgotForm" *ngIf="showforgot">
            <h1 class="mb-2"><b class="fw-bold tx-primary"><span class="tx-warning">Digi</span>SME</b></h1>
            <h4 class="tx-primary mb-4">Forgot Password</h4>
            <div class="mb-4">
              <label class="form-control-label">Email or Mobile Number</label>
              <input type="text" appAutofocus tabindex="1" class="form-control form-control-lg rounded-pill"
                formControlName="UserName" placeholder="Enter your Email/Mobile Number">
            </div>
            <div class="mb-3">
              <button type="submit" (click)="Forgot()"
                class="btn btn-lg btn-primary rounded-pill fw-bold ripple w-100">SEND</button>
            </div>
            <div class="col col-auto">
              <button (click)="ForgotType()" type="button"
                class="btn btn-sm btn-link fw-bold text-decoration-none p-0 d-flex align-items-center"><i
                  class="fa-solid fa-angle-left me-1"></i>Back to Login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</div>
