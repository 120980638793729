import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { LocalCacheService } from './local-cache.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { responsecls } from '@utilitymodel/responsecls';
import { environment } from 'src/environments/environment';
import { Country, AdminLogin } from '@utilitymodel/admin-login.model';

@Injectable({
  providedIn: 'root'
})
export class AdminauthenticationService {

  public currentUserSubject!: BehaviorSubject<AdminLogin>;
  public currentUser!: Observable<any>;

  us: any;
  private refreshTokenTimeout: any;
  idleState = 'Not started.';
  timedOut = false;
  
  constructor(private http: HttpClient, private router: Router, private local: LocalCacheService,
    private encdec: EncryptDecryptService){

      if (localStorage.getItem(this.local.localUserKey) != null) {
        this.us = this.encdec.decryptData(localStorage.getItem(this.local.localUserKey));
        // let selectCompany = this.encdec.decryptData(localStorage.getItem(this.local.localCurrentUserKey));
        // this.currentCompany = new BehaviorSubject(selectCompany);
      }
      else {
        this.us = null;
      }
      this.currentUserSubject = new BehaviorSubject<AdminLogin>(this.us);
      this.currentUser = this.currentUserSubject.asObservable();

    }

    login(signInData: any) {
      return this.http.post<any>(environment.authguardApiUrl + '/AdminLogin/AuthenticateInfotechLogin', signInData)
        .pipe(map((user: responsecls) => {
          // if (user.IsSuccess) {
            // localStorage.setItem(this.local.localUserKey, this.encdec.encryptData(user.StatusObject));
            // this.local.getLocalStorageSize();
          //   let filterCompany = user.StatusObject.UserData._usercompany.find((r: any) => r.Company_Id === user.StatusObject.UserData.CompanyID)
  
          //   if (filterCompany === undefined && user.StatusObject.UserData._usercompany.length == 0) {
          //     localStorage.setItem(this.local.localUserKey, this.encdec.encryptData(user.StatusObject));
          //     this.local.getLocalStorageSize();
          //     this.currentUserSubject.next(user.StatusObject);
          //     return user;
          //   } else if (filterCompany === undefined) {
          //     filterCompany = user.StatusObject.UserData._usercompany[0];
          //   }
          //   console.log(filterCompany);
  
  
          //   if (user.StatusObject?.UserData.Country == Country.Singapore) {
          //     environment.leaveApiUrl = environment.sgLeaveApiUrl;
          //   } else if (user.StatusObject?.UserData.Country == Country.NewzLand) {
          //     environment.leaveApiUrl = environment.NZleaveApiUrl;
          //   } else {
          //     environment.leaveApiUrl = environment.glleaveApiUrl;
          //   }
  
          //   // this.selectCompany(filterCompany, user.StatusObject);
          // }
          debugger;
          this.currentUserSubject.next(user.StatusObject);
          localStorage.setItem(this.local.localUserKey, this.encdec.encryptData(user.StatusObject));
          this.local.getLocalStorageSize();
          console.log(user)
          console.log(this.UserValue)
          return user;
        }));
    }
    public get UserValue() {
      return this.currentUserSubject.value;
    };
    rememberMe(obj: any) {
      localStorage.setItem(this.local.rememberMe, this.encdec.encryptData(obj));
    }

    logout() {
      this.local.removeLocalCache();
      this.currentUserSubject.next({} as AdminLogin);
      window.location.href = '/Admin/Login';
      // this.currentCompany.next(null);
    }

    checkrememberMe() {
      let data = null;
      if (localStorage.hasOwnProperty(this.local.rememberMe)) {
        data = localStorage.getItem(this.local.rememberMe);
        if (!!data) {
          data = (this.encdec.decryptData(data));
        }
      }
      return data;
    }
}
