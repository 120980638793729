import { Component } from '@angular/core';
import { IconSetService } from '@coreui/icons-angular';
import { cilListNumbered, cilPaperPlane, brandSet, cilCommentSquare, cilSettings,cilFile,cilLockLocked ,cilCreditCard,cilUser ,cilTask    } from '@coreui/icons';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'DigiSME';

 userInput: string='';
  constructor(public iconSet: IconSetService, ) {
    this.iconSet.icons = { cilListNumbered, cilPaperPlane, cilCommentSquare, cilSettings ,cilFile,cilLockLocked,cilCreditCard ,cilUser ,cilTask    };
   
  }
  
  
}
