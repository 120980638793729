import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '@utilityService/loader.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private loaderService: LoaderService) {

    this.loaderService.isLoading
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          setTimeout(() => { this.isLoading = res; });
        });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

}
