
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  constructor(public modalService: NgbModal) { }

  public successModal(
    title: string,
    message: string,
    btnOkText: string = 'Okay',
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmModalComponent,
      {
        windowClass: 'modal-holder', backdrop: 'static', keyboard: false,
        centered: true
      });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.type = 'success';
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    return modalRef.result;
  }

  public warningModal(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm' | 'lg' = 'lg'): Promise<boolean> {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      windowClass: 'modal-holder', backdrop: 'static', keyboard: false,
      centered: true
    });
    modalRef.componentInstance.type = 'warning';
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  public errorModal(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      windowClass: 'modal-holder', backdrop: 'static', keyboard: false,
      centered: true
    });
    modalRef.componentInstance.type = 'error';
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  public deleteModal(
    title: string,
    message: string,
    btnOkText: string = 'Yes',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      windowClass: 'modal-holder', backdrop: 'static', keyboard: false,
      centered: true
    });
    modalRef.componentInstance.type = 'delete';
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  public confirmModal(
    title: string,
    message: string,
    icon: string = '',
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      windowClass: 'modal-holder', backdrop: 'static', keyboard: false,
      centered: true
    });
    modalRef.componentInstance.type = 'confirm';
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.icon = icon;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  public unAuthorized(): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      windowClass: 'modal-holder', backdrop: 'static', keyboard: false,
      centered: true
    });
    modalRef.componentInstance.type = 'unAuthorized';




    return modalRef.result;
  }

  public planPopupModal(

  ): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      windowClass: 'modal-holder', backdrop: 'static', keyboard: false,
      centered: true
    });
    modalRef.componentInstance.type = 'plan';




    return modalRef.result;
  }

  public playYoutube(VideoId: string

  ): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      windowClass: 'modal-holder',  modalDialogClass:'yt', size:'lg',
      centered: false
    });
    modalRef.componentInstance.type = 'YT';
    modalRef.componentInstance.videoId = VideoId;




    return modalRef.result;
  }
}
