import { Component, Input, OnInit } from '@angular/core';
import { WidgetModule } from '@coreui/angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from '@utilityService/master.service';
import { Observable, Subscription } from 'rxjs';
import { interval, take } from 'rxjs';
let apiLoaded = false;
@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  @Input() title: string = 'Are You Sure';
  @Input() message: string = 'Message';
  @Input() btnOkText: string = 'Okay';
  @Input() btnCancelText: string = 'Cancel';
  @Input() type!: String;
  @Input() icon: string = '';
  @Input() videoId!: string;
  sub!: Subscription;
  num: number = 0;
  playerVars = { rel: 0, modestbranding: 1, FullscreenButton: 1, };
  constructor(private activeModal: NgbActiveModal, private masterService: MasterService) {

    if (!apiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      apiLoaded = true;
    }

  }

  ngOnInit(): void {
    if (this.type == 'unAuthorized') {
      const numbers = interval(1000);
      const takeFourNumbers = numbers.pipe(take(12));
      takeFourNumbers.subscribe(x => {
        if (x == 11) {
          this.accept();
          return;
        }
        this.num = x

      });
    }
  }

  accept() {
    this.activeModal.close(true);
  }
  OnClickViewPlan() {
    this.activeModal.close(true)
    // this.getPlan()
  }

  decline() {
    this.activeModal.close(false);
  }


  public dismiss() {
    this.activeModal.dismiss();
  }

  //   getPlan(): void {
  //     let obj = {
  //         "CustomerCode":"100001",
  //         "CountryId": "2",
  //         "ReturnUrl":"https://stgdigisme.azurewebsites.net",
  //         "EmailId":"selvendranm@info-tech.co.in",
  //     }
  //    this.masterService.GetViewPlan(obj).subscribe((r: any) => {
  //       if(r.IsSuccess){
  //         console.log(r)
  //         window.open(`https://stgdigicrm.azurewebsites.net/checkout?SessionId=${r.StatusObject[0]['SessionId']}`, '_blank');
  //       } else {
  //          console.log("something went worng")
  //       }
  //     });
  // }

  player: any;
  savePlayer(player: any) {
    this.player = player;
    setTimeout(() => {
      this.player.target.playVideo();
    }, 1000);
    // this.player.target.playVideo();
  }
}
