import { AlertMessage } from '@utilityhelper/alert.message';
import { PageServices } from './../_services/page.service';
import { Login, UserMenu } from '@utilitymodel/login.model';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@utilityService/authentication.service';
import { responsecls } from '@utilitymodel/responsecls';
import { LocalCacheService } from '@utilityService/local-cache.service';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {

  toastId = 0;
  constructor(private router: Router, private authenticationService: AuthenticationService, private alert: AlertMessage, private local: LocalCacheService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,) {

    let output: any = this.authenticationService.checkrememberMe();
    if (!!output) {
      this.login(output);
    } else {
      return true;
    }
    return false;
  }


  login(loginRequest: any) {


    this.authenticationService.login(loginRequest).subscribe((r: responsecls) => {
      if (r.IsSuccess) {

        this.router.navigate(['/Digi/dashboard']);
      } else {
        this.toastId = this.alert.errorNotifier(r.StatusMessage, this.toastId);
        this.local.removelocalStorage(this.local.rememberMe);
      }
    });
  }



}

