import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class SanitizeInterceptor implements HttpInterceptor {

  private dangerousPatterns: RegExp[] = [
    /^=/,
    /^\+/,
    /^-/,
    /^@/,
    /^DDE/i
  ];

  constructor(private router: Router) { }

  private containsDangerousPattern(value: string): boolean {
    if (typeof value === 'string') {
      for (const pattern of this.dangerousPatterns) {
        if (pattern.test(value)) {
          return true;
        }
      }
    }
    return false;
  }

  private sanitize(value: any): any {
    if (typeof value === 'string') {
      for (const pattern of this.dangerousPatterns) {
        value = value.replace(pattern, '');
      }
      return value;
    } else if (Array.isArray(value)) {
      return value.map(item => this.sanitize(item));
    } else if (typeof value === 'object' && value !== null) {
      const sanitizedObject: any = {};
      for (const key of Object.keys(value)) {
        sanitizedObject[key] = this.sanitize(value[key]);
      }
      return sanitizedObject;
    }
    return value;
  }

  private traverseAndCheck(value: any): boolean {
    if (typeof value === 'string') {
      return this.containsDangerousPattern(value);
    } else if (Array.isArray(value)) {
      return value.some(item => this.traverseAndCheck(item));
    } else if (typeof value === 'object' && value !== null) {
      return Object.values(value).some(val => this.traverseAndCheck(val));
    }
    return false;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes("AttachmentUpload") && !req.url.includes("Attachment") && !req.url.includes("verifykey") && !req.url.includes("stgauthguard.azurewebsites.net") && req.url.includes("liveauthguarddigisme.azurewebsites.net")) {
      if (req.body && this.traverseAndCheck(req.body)) {
        this.router.navigate(['/login']);
        throw new Error('Dangerous input detected, navigating to login.');
      }

      const sanitizedBody = this.sanitize(req.body);
      const sanitizedReq = req.clone({ body: sanitizedBody });
      return next.handle(sanitizedReq);
    }
    return next.handle(req);
  }
}
