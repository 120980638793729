export const environment = {
  production: false,
  adminApiUrl: 'https://stgadmin.azurewebsites.net/api',
  authguardApiUrl: 'https://stgauthguard.azurewebsites.net/api',
  glleaveApiUrl: 'https://stgleave.azurewebsites.net/api',
  tmsApiUrl: 'https://stgtms.azurewebsites.net/api',
  sgLeaveApiUrl: 'https://stgleavesg.azurewebsites.net/api',
  payrollApiUrl: 'https://stgpayrollsg.azurewebsites.net/api',
  crmPaymentApiUrl: 'https://stgcrmreplica.azurewebsites.net/api',
  crmApiUrl: 'https://stagingcrmapi.azurewebsites.net/api',
  crmdigiUrl: 'https://stagingdigismecrm.azurewebsites.net',
  NzadminApiUrl: 'https://stgadmin.azurewebsites.net/api',
  crmReutnrURL:'https://stgdigisme.azurewebsites.net/Digi/dashboard',
  NZleaveApiUrl:'https://stgleavenz.azurewebsites.net/api',
  NzpayrollApiUrl: 'https://stgpayrollnz.azurewebsites.net/api',
  sgOutlookMeetingURL: 'https://outlook.office.com/bookwithme/user/b769f6b5c1b94bd684851108f614d9a2%40info-tech.co.in/meetingtype/T2P4qNJJoEiWNxB1XePTfw2?anonymous',
  NzOutlookMeetingURL: 'https://outlook.office.com/bookwithme/user/b769f6b5c1b94bd684851108f614d9a2%40info-tech.co.in/meetingtype/T2P4qNJJoEiWNxB1XePTfw2?anonymous',
  PayDayFillingReDirectURL: 'https://staging.digi-sme.com/Digi/PaySubmission',
  PayDayFillingGovUrl: 'https://test5.services.ird.govt.nz/gateway3/oauth/authorize?response_type=code',
  leaveApiUrl: '',
  MyLeaveApiUrl: 'https://stgleavemy.azurewebsites.net/api',
  MypayrollApiUrl: 'https://stgpayrollmy.azurewebsites.net/api',
  isEncDecAllowed: true,
  isResponseEncAllowed: false
  




};



