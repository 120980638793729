import { Injectable } from '@angular/core';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { HttpService } from './http.service';
import { Buffer } from 'buffer'
@Injectable({
  providedIn: 'root'
})
export class LocalCacheService {

  public cacheSize: any;
  public localUserKey: string;
  public localCurrentUserKey: string;
  public rememberMe: string;
  encodedata!: string;
  deodedata!: string;

  constructor(private encdec: EncryptDecryptService) {
    this.cacheSize = "0 KB";
    this.localUserKey = Buffer.from("User").toString('base64');
    this.rememberMe = Buffer.from("RememberMe").toString('base64');
    this.localCurrentUserKey = Buffer.from("CurrentUser").toString('base64');
  }

  setlocalStorage(key: string, data: any) {
    this.encodedata = this.encdec.encryptData(data);
    localStorage.setItem(key, this.encodedata);
  }

  getlocalStorage(key: string) {
    this.encodedata = localStorage.getItem(key)!;
    if (this.encodedata != null) {
      this.deodedata = this.encdec.decryptData(this.encodedata)
      return this.deodedata;
    } else {
      return null;
    }
  }

  removelocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  removeLocalCache() {
    let rememberData: any;
    if (localStorage.hasOwnProperty(this.rememberMe)) {
      rememberData = localStorage.getItem(this.rememberMe);
    }

    localStorage.clear();
    if (!!rememberData)
      localStorage.setItem(this.rememberMe, rememberData);
  }

  getLocalStorageSize() {
    var data = '';
    for (var key in window.localStorage) {
      if (window.localStorage.hasOwnProperty(key)) {
        data += window.localStorage[key];
      }
    }
    this.cacheSize = (data ? '\n' + 'localStorage : (' + ((data.length * 16) / (8 * 1024)).toFixed(2) + ' KB)' : 'localStorage (0 KB)');
    return this.cacheSize;
  }
}
