import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertMessage } from '@utilityhelper/alert.message';
import { LocalCacheService } from '@utilityService/local-cache.service';
import { LoginRequest } from '@utilitymodel/login.model';
import { responsecls } from '@utilitymodel/responsecls';
import { AdminauthenticationService } from '@utilityService/adminauthentication.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {

  loginRequest: LoginRequest = {} as LoginRequest;
  loginForm: FormGroup;
  ForgotForm: FormGroup;
  toastId = 0;
  showforgot = false;
  pwdType: string = 'password'
  
  constructor(private router: Router, private fb: FormBuilder, private modal: NgbModal,
    private alert: AlertMessage, private authService: AdminauthenticationService,
    private local: LocalCacheService,) {
    this.loginForm = this.fb.group({
      UserName: ['', Validators.required],
      Password: ['', Validators.required],
      RememberMe: [false]
    });
    this.ForgotForm = this.fb.group({
      UserName: ['', Validators.required]
    });
    this.local.removeLocalCache();

  }

  ngOnInit(): void {
    this.local.removeLocalCache();
    this.modal.dismissAll();
    // let output: any = this.authService.checkrememberMe();
    // if (!!output) {
    //   this.loginForm.patchValue(output);
    //   this.login();

    // }
  }

  login() {
    if (this.loginForm.invalid) {
      this.toastId = this.alert.errorNotifier('Please fill in the login details.', this.toastId)
      return;
    }
    this.loginRequest = Object.assign(this.loginRequest, this.loginForm.value)


    this.authService.login(this.loginRequest).subscribe((r: responsecls) => {
      if (r.IsSuccess) {
        if (this.loginForm.value.RememberMe) {
          this.authService.rememberMe(this.loginForm.value);
        }
        // r.StatusObject.UserData.Country != '1'? this.authService.sendCRMUpdateLogin() : console.log("...");
        localStorage.removeItem("PayPeriod");
        this.router.navigate(['/Admin/Reports/customerLicenseDetails']);
      } else {
        this.toastId = this.alert.errorNotifier(r.StatusMessage, this.toastId);
      }
    }, e => {
      console.log(e)
      this.toastId = this.alert.errorNotifier("Unable to connect to the server.", this.toastId);
    });
  }

  ForgotType() {
    this.showforgot = false;
  }

  loginType() {
    this.showforgot = true;
  }

  Forgot() {
    // if (this.ForgotForm.invalid) {
    //   this.toastId = this.alert.errorNotifier('Please fill Email/Mobile Number.', this.toastId)
    //   return;
    // }
    // this.loginRequest = Object.assign(this.loginRequest, this.ForgotForm.value);

    // this.authService.ForgotPassword(this.loginRequest).subscribe((r: responsecls) => {
    //   if (r.IsSuccess) {
    //     this.toastId = this.alert.successNotifier(r.StatusMessage, this.toastId);
    //     this.showforgot = false;

    //   }
    //   else {
    //     this.toastId = this.alert.errorNotifier('Please Enter Valid Email.', this.toastId);
    //   }
    // }, e => {
    //   this.toastId = this.alert.errorNotifier("Unable to connect to the server.", this.toastId);
    // });
  }

  navigatetoSME() {
    window.location.href = 'https://www.digi-sme.com/'
  }

}
