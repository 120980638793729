import { TimeMaskDirective } from './../time-mask.directive';
import { UserAccessDirectiveModule, userCanEditDirective } from './../userRole.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutofocusDirective } from '../autofocusdirective';
import { DragDirective } from '../dragDrop.directive';
import { AllowKeypressDirectiveModule } from '../allowkeypress.directive';
import { InvalidFocusDirective } from '../invalidFocusDirective ';



@NgModule({
  declarations: [DragDirective, AutofocusDirective, InvalidFocusDirective, TimeMaskDirective],
  imports: [
    CommonModule, AllowKeypressDirectiveModule, UserAccessDirectiveModule
  ],
  exports: [DragDirective, AutofocusDirective, AllowKeypressDirectiveModule, InvalidFocusDirective, UserAccessDirectiveModule, TimeMaskDirective]
})
export class DirectiveModule { }
