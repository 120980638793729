import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { SuccessDialogService } from 'src/app/views/shared/success/success-dialog.service';
import { AuthenticationService } from '../_services/authentication.service';
@Injectable({
  providedIn: 'root'
})

export class AlertMessage {

  constructor(private router: Router, private toastr: ToastrService
  ) {

  }

  //please don't create new notifier alert method

  successVerificationNotifier(message: any) {
    // this.successDialogService.openDialog(message);
  }

  successVerificationroutingNotifier(message: any, url: string, queryParams: any) {
    // this.successDialogService.openDialogrouting(message, url, queryParams);
  }

  successNotifier(message: any, id: number) {
    this.toastr.remove(id);
    const activeToast = this.toastr.success(
      message,
      'Success!',
      {
        enableHtml: true,
        timeOut: 3000,
        tapToDismiss: false,
        closeButton: true,
      },
    );
    return activeToast.toastId;
  }

  infoNotifier(message: any, id: number) {
    this.toastr.remove(id);
    const activeToast = this.toastr.info(
      message,
      'Information!',
      {
        enableHtml: true,
        timeOut: 3000,
        tapToDismiss: false,
        closeButton: true,
      }
    );
    return activeToast.toastId;
  }

  errorNotifier(message: any, id: number) {
    this.toastr.remove(id);
    const activeToast = this.toastr.error(
      message,
      'Error!',
      {
        enableHtml: true,
        timeOut: 3000,
        tapToDismiss: false,
        closeButton: true,
      }
    );
    return activeToast.toastId;
  }

  errorNotifierList(lstErrors: any[], id: number) {
    let message = "";
    // if (lstErrors.length > 0) {
    //   for (var i = 0; i < lstErrors.length; i++) {
    //     message = message + '<li>' + lstErrors[i] + '</li>';
    //   }
    //   message = "<ul>" + message + "</ul>";

    //   this.toastr.remove(id);
    //   const activeToast = this.toastr.error(
    //     message,
    //     'Error!',
    //     {
    //       enableHtml: true,
    //       timeOut: 8000,
    //       tapToDismiss: false,
    //       closeButton: true,
    //     }
    //   );
    //   return activeToast.toastId;
    // }
    // else {
    //   return 0;
    // }
  }

  errorTitleNotifier(message: any, title: string, id: number) {
    this.toastr.remove(id);
    const activeToast = this.toastr.error(
      message,
      title,
      {
        enableHtml: true,
        timeOut: 3000,
        tapToDismiss: false,
        closeButton: true,
      }
    );
    return activeToast.toastId;
  }

  warningNotifier(message: any, id: number) {
    this.toastr.remove(id);
    const activeToast = this.toastr.warning(
      message,
      'Warning!',
      {
        enableHtml: true,
        timeOut: 3000,
        tapToDismiss: false,
        closeButton: true,
      }
    );
    return activeToast.toastId;
  }

  errorNotifierManualClose(lstErrors: any, id: number) {
    let message = "";
    if (lstErrors.length > 0) {
      for (var i = 0; i < lstErrors.length; i++) {
        message = message + '<li>' + lstErrors[i] + '</li>';
      }
      message = "<ul>" + message + "</ul>";

      this.toastr.remove(id);
      const activeToast = this.toastr.error(
        message,
        'Error!',
        {
          enableHtml: true,
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true,
        }
      );
      return activeToast.toastId;
    }
    else {
      return 0;
    }
  }

  successRoutingNotifier(message: any, url: string, queryParams: any) {
    const activeToast = this.toastr.success(
      message,
      'Success!',
      {
        enableHtml: true,
        timeOut: 1000,
        tapToDismiss: false,
        closeButton: true,
      },
    );
    setTimeout(() => {
      if (queryParams == '' || queryParams == undefined) {
        this.router.navigate([url]);
      }
      else {
        this.router.navigate([url], { queryParams });
      }
    }, 1000);
  }

  errorRoutingNotifier(message: any, url: string, queryParams: any) {
    const activeToast = this.toastr.error(
      message,
      'Error!',
      {
        enableHtml: true,
        timeOut: 2000,
        tapToDismiss: false,
        closeButton: true,
      },
    );
    setTimeout(() => {
      if (queryParams == '' || queryParams == undefined) {
        this.router.navigate([url]);
      }
      else {
        this.router.navigate([url], { queryParams });
      }
    }, 2000);
  }

  clearNotifier() {
    this.toastr.clear();
  }

  errorSummaryNotifierWithAutoClose(lstErrors: any, id: number) {
    // let message = "";
    // if (lstErrors.length > 0) {
    //   for (var i = 0; i < lstErrors.length; i++) {
    //     message = message + '<li>' + lstErrors[i] + '</li>';
    //   }
    //   message = "<ul>" + message + "</ul>";

    //   this.toastr.remove(id);
    //   const activeToast = this.toastr.error(
    //     message,
    //     'Error!',
    //     {
    //       enableHtml: true,
    //       tapToDismiss: true,
    //       closeButton: true,
    //       timeOut: 5000,
    //     }
    //   );
    //   return activeToast.toastId;
    // }
    // else {
    //   return 0;
    // }
  }
}
